@import "./brand";
@import "./bootstrap-custom";

.over-fade {
    opacity: 0.7 !important;
    transition: opacity 0.5s;
}

.over-fade:hover {
    opacity: 1 !important;
}